import img1 from '../../../../img/about_1.jpg';
import img1Webp from '../../../../img/about_1.webp';
import img1Avif from '../../../../img/about_1.avif';

import img2 from '../../../../img/about_2.jpg';
import img2Webp from '../../../../img/about_2.webp';
import img2Avif from '../../../../img/about_2.avif';

import img3 from '../../../../img/about_3.jpg';
import img3Webp from '../../../../img/about_3.webp';
import img3Avif from '../../../../img/about_3.avif';

import img4 from '../../../../img/about_4.jpg';
import img4Webp from '../../../../img/about_4.webp';
import img4Avif from '../../../../img/about_4.avif';

import img5 from '../../../../img/about_5.jpg';
import img5Webp from '../../../../img/about_5.webp';
import img5Avif from '../../../../img/about_5.avif';

import img6 from '../../../../img/about_6.jpg';
import img6Webp from '../../../../img/about_6.webp';
import img6Avif from '../../../../img/about_6.avif';

import img7 from '../../../../img/about_7.jpg';
import img7Webp from '../../../../img/about_7.webp';
import img7Avif from '../../../../img/about_7.avif';

import img8 from '../../../../img/about_8.jpg';
import img8Webp from '../../../../img/about_8.webp';
import img8Avif from '../../../../img/about_8.avif';

import img9 from '../../../../img/about_9.jpg';
import img9Webp from '../../../../img/about_9.webp';
import img9Avif from '../../../../img/about_9.avif';

import img10 from '../../../../img/about_10.jpg';
import img10Webp from '../../../../img/about_10.webp';
import img10Avif from '../../../../img/about_10.avif';


export const photos = [
  {
    img: img1,
    imgWebp: img1Webp,
    imgAvif: img1Avif,
  },
  {
    img: img2,
    imgWebp: img2Webp,
    imgAvif: img2Avif,
  },
  {
    img: img3,
    imgWebp: img3Webp,
    imgAvif: img3Avif,
  },
  {
    img: img4,
    imgWebp: img4Webp,
    imgAvif: img4Avif,
  },
  {
    img: img5,
    imgWebp: img5Webp,
    imgAvif: img5Avif,
  },
  {
    img: img6,
    imgWebp: img6Webp,
    imgAvif: img6Avif,
  },
  {
    img: img7,
    imgWebp: img7Webp,
    imgAvif: img7Avif,
  },
  {
    img: img8,
    imgWebp: img8Webp,
    imgAvif: img8Avif,
  },
  {
    img: img9,
    imgWebp: img9Webp,
    imgAvif: img9Avif,
  },
  {
    img: img10,
    imgWebp: img10Webp,
    imgAvif: img10Avif,
  }];
